var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 pt-0 pb-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('PageHeader',{staticClass:"pb-1",attrs:{"title":_vm.title}})],1),(_vm.canGoConfigCotizador)?_c('v-col',{staticClass:"pt-3",attrs:{"cols":"6"}},[_c('v-tooltip',{staticClass:"pt-10",attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"to-right",attrs:{"icon":""},on:{"click":function($event){return _vm.goToConfigCotizador()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30px"}},[_vm._v(_vm._s(_vm.settingsIcon))])],1)]}}],null,false,3187344182)},[_c('span',[_vm._v("Acceder a configuración de cotizador")])])],1):_vm._e()],1),_c('v-card',{staticClass:"mx-auto mb-3 pt-0",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1),_c('v-col',{staticClass:"py-0 pb-2",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();_vm.showFilters = !_vm.showFilters}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',{attrs:{"mode":"in-out"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form","dense":""},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"label":"N° de cotización","outlined":"","clearable":"","dense":""},model:{value:(_vm.nroCotizador),callback:function ($$v) {_vm.nroCotizador=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nroCotizador"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Nombre destinatario","outlined":"","clearable":"","dense":"","disabled":_vm.nroCotizador !== null && _vm.nroCotizador !== '',"rules":_vm.nombreDestinatario
                        ? _vm.rules.required.concat([
                            _vm.rules.requiredTrim(_vm.nombreDestinatario),
                            _vm.rules.maxLength(_vm.nombreDestinatario, 40)
                          ])
                        : []},model:{value:(_vm.nombreDestinatario),callback:function ($$v) {_vm.nombreDestinatario=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombreDestinatario"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsVendedores,"label":"Vendedores","item-text":"value","item-value":"id","disabled":_vm.nroCotizador !== null && _vm.nroCotizador !== '',"return-object":"","outlined":"","clearable":"","dense":""},model:{value:(_vm.vendedoresSelected),callback:function ($$v) {_vm.vendedoresSelected=$$v},expression:"vendedoresSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsTiposFecha,"return-object":"","label":"Tipo fecha","item-text":"value","item-value":"id","disabled":_vm.nroCotizador !== null && _vm.nroCotizador !== '',"outlined":"","dense":""},model:{value:(_vm.tipoFechaSelected),callback:function ($$v) {_vm.tipoFechaSelected=$$v},expression:"tipoFechaSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":_vm.tipoFechaSelected.value + ' desde',"append-icon":_vm.calendarIcon,"disabled":_vm.nroCotizador !== null && _vm.nroCotizador !== '',"outlined":"","clearable":"","dense":"","hint":"Formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","rules":_vm.fechaDesdeSelected
                            ? _vm.rules.validDate.concat(
                                new Date(
                                  _vm.parseDateToIso(_vm.fechaDesdeSelected)
                                ) <=
                                  new Date(
                                    _vm.parseDateToIso(_vm.fechaHastaSelected)
                                  ) || 'Formato incorrecto'
                              )
                            : []},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeSelected)}},model:{value:(_vm.fechaDesdeSelected),callback:function ($$v) {_vm.fechaDesdeSelected=$$v},expression:"fechaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaDesdeSelected = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","disabled":_vm.nroCotizador !== null,"max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":_vm.tipoFechaSelected.value + ' hasta',"append-icon":_vm.calendarIcon,"disabled":_vm.nroCotizador !== null && _vm.nroCotizador !== '',"outlined":"","clearable":"","dense":"","hint":"Formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","rules":_vm.fechaHastaSelected
                            ? _vm.rules.validDate.concat(
                                new Date(
                                  _vm.parseDateToIso(_vm.fechaDesdeSelected)
                                ) <=
                                  new Date(
                                    _vm.parseDateToIso(_vm.fechaHastaSelected)
                                  ) || 'Formato incorrecto'
                              )
                            : []},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaSelected)}},model:{value:(_vm.fechaHastaSelected),callback:function ($$v) {_vm.fechaHastaSelected=$$v},expression:"fechaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaHastaSelected = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menu2 = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1),_c('v-col',{staticClass:"py-0 pt-3",attrs:{"col":"3","align":"right"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","elevation":"2","small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsCotizador,"search":_vm.search,"loading":_vm.isLoading,"item-key":"cotiCabeId","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"2","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModalEditCotizador()}}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModalEditCotizador(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar cotización")])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteCotizador(item.cotiCabeId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar cotización")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.printCotizacion(item.cotiCabeId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.pdfIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Imprimir pdf")])])]}},{key:"item.data-table-expand",fn:function(ref){
                            var item = ref.item;
                            var isExpanded = ref.isExpanded;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(isExpanded ? "mdi-account-check" : "mdi-account"))])]}}],null,true)},[_c('span',[_vm._v("Usuarios")])])]}},{key:"expanded-item",fn:function(ref){
                            var headers = ref.headers;
                            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Fecha alta:")]),_vm._v(" "+_vm._s(item.fechaAlta)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Usuario alta:")]),_vm._v(" "+_vm._s(item.usuAlta)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Fecha modificación:")]),_vm._v(" "+_vm._s(item.fechaModi)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Usuario modificación:")]),_vm._v(" "+_vm._s(item.usuModi)+" ")])],1)],1)]}}],null,true)})],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"py-3",attrs:{"cols":"6","md":"7","align":"right"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"fontsize",attrs:{"color":"primary","outlined":"","disabled":_vm.itemsCotizador.length === 0},on:{"click":_vm.exportExcelModelo}},'v-btn',attrs,false),[_vm._v(" Exportar detalle ")])]}}])})],1)],1),(_vm.openModalCotizadorEdit)?_c('v-dialog',{attrs:{"max-width":"65%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openModalCotizadorEdit),callback:function ($$v) {_vm.openModalCotizadorEdit=$$v},expression:"openModalCotizadorEdit"}},[_c('EditCotizador',{attrs:{"itemCotizador":_vm.itemToEdit,"enviarCategoria":_vm.enviarCategoria},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"isLoading":_vm.loadingBtnDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1),_c('v-col',{staticClass:"to-right pt-0 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }